.newbutton{
    width: '100%';
    max-width : '200px';
    padding: '10px 20px';
    background-color: '#007bff';
    color: '#fff';
    border: 'none';
    border-radius: '5px';
    cursor: 'pointer';
    display: 'block';
    margin: 'auto';
}